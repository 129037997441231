import React            from 'react';
import PropTypes        from 'prop-types';
import ClassNames       from 'classnames';
// import moment           from 'moment';

import * as S             from './price.module.scss';
// import { useGetSkuPrice } from '../../../helpers/hooks/use-get-price';
import { useGlobalCtx }   from '../../../context/ctx-hook';
import { getPriceBySku }  from '../../../context/selectors/product-selectors';
import { Discount }       from './discount';


const Price = ({renewalFrequency, className, sku, price, discountObj}) => {
  const {state} = useGlobalCtx();
  // const renewalDuration = moment.duration(renewalFrequency);
  // const renewalText = renewalDuration.humanize();
  let IS_YEARLY = renewalFrequency.slice(-1) === 'Y';
  let IS_MONTHLY = renewalFrequency.slice(-1) === 'M';
  // let IS_DAILY = renewalFrequency.slice(-1) === 'D';
  
  let intervalStr = '';
  if (IS_YEARLY) { intervalStr = 'yr'; }
  if (IS_MONTHLY) { intervalStr = 'mo'; }
  // if (IS_DAILY) { intervalStr = 'days'; }

  let HAS_DISCOUNT = discountObj && discountObj?.DisplayFullPrice != '';

  // useGetSkuPrice(sku);
  const PRICE = getPriceBySku(state, sku);

  return (
    <div>
      {HAS_DISCOUNT && <Discount discountPrice={discountObj?.DisplayFullPrice} displayDiscount={discountObj?.DisplayDiscount}/>}
      <div className={ClassNames(S.priceArea, className)} data-testid="SUB_PRICE">
        {(PRICE || price) && <span className={S.price}>{price || PRICE}</span> }
        {(PRICE || price) && <span className={S.dash}>/</span> }
        {(PRICE || price) && <span className={S.interval}>{intervalStr}</span> }
      </div>
    </div>
  );
};

Price.displayName = 'Price';
Price.propTypes   = {
  price:            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sku:              PropTypes.string,
  className:        PropTypes.string,
  product:          PropTypes.string,
  renewalFrequency: PropTypes.string,
  discountObj:      PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

Price.defaultProps = {
  className: null
};

export { Price };
