import PropTypes  from 'prop-types';
import React      from 'react';
import classNames from 'classnames';

import * as S                  from './qob-paid.module.scss';

import {
  getProductsPaymentMethod,
  getUserSubHistory
} from '../../../../context/selectors/user-selectors';

import { Legal }               from '../../../common/legal';
import { Expiry }              from '../../../common/product/expiry';
import { Price }               from '../../../common/product/price';
import { ProductName }         from '../../../common/product/prod-name';
import { TagLine }             from '../../../common/product/tag-line';
import { CancelledBox }        from '../../../common/product/cancelled-box';

import * as GlobalActions      from '../../../../context/ctx-actions';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import * as ProductSelectors   from '../../../../context/selectors/product-selectors';

import { isInBetween }                from '../../../../helpers/time-helpers';  
import * as StateConstants            from '../../../../helpers/constants/state-constants';
import { useAutoRenew }               from '../../../../helpers/hooks/use-auto-renew';
import { getEStoreCartUrlAddProduct } from '../../../../helpers/route-helper';
import { QOB_REDIRECT_SKU }           from '../../../../helpers/constants/constants';

const PRODUCT = 'quicken-online-backup';

const getAccountMsg = ({ active, autoRenew }, qknIsActive, qknHasArOn, isInGracePeriod) => {
  if (!active) return false;
  else if (isInGracePeriod) return 'Your subscription has expired. You will have access to your data for 90 days after the expiration date, after which it will be permanently deleted.';
  else if (!qknIsActive && (!autoRenew) || !qknHasArOn ) return 'You must resume your Classic subscription before resuming Online Backup.';
  else if (!autoRenew) return 'In order to not lose your connected services, you need to resume your subscription';
  else return '';
};

const QobPaid = ({isModal, hideCircle, qknIsActive, qknHasArOn, listPosition}) => {
  const {globalDispatch, state} = useGlobalCtx();

  const PAYMENT_DEPENDENCY = 'quicken-us';
  const HAS_PAYMENT  = getProductsPaymentMethod(PAYMENT_DEPENDENCY, state);
  const SUB          = ProductSelectors.getSubscription(state, PRODUCT);
  const { active, autoRenew, tierName, renewalFrequency, expireOn, effectiveAt, isInGracePeriod } = SUB;
  const MESSAGE = getAccountMsg(SUB, qknIsActive, qknHasArOn, isInGracePeriod);
  const HISTORY_LIST = getUserSubHistory(state, PRODUCT);
  const showDash = (qknIsActive && qknHasArOn) || isInGracePeriod;

  const handleAROn = useAutoRenew(PRODUCT, true);

  const handleBuyNow = () => {
    window.location.href = getEStoreCartUrlAddProduct(QOB_REDIRECT_SKU);
  };

  const manageSubscription = React.useCallback(
    async () => globalDispatch(GlobalActions.setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: PRODUCT},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        ((action === 'DISABLE_AUTORENEW' || action === 'SOFT_CANCEL') && isInBetween(SUB.effectiveAt, expireOn, createdAt))
      ).length > 0),
    [autoRenew, effectiveAt, expireOn, HISTORY_LIST]
  );

  const SKU = ProductSelectors.uriNameToSku(SUB.tierUriName);
  const PRICE =  ProductSelectors.getPriceBySku(state, SKU);

  const hangLengthClass = ( listPosition > 0 ? (listPosition > 1 ? S.extraHanging : S.longHanging) : S.shortHanging );

  return (
    
    <div className={`${isModal ? S.qobModalTile : S.qobTile} ${SUB.tierUriName}`}>

      {!isModal && !hideCircle && <span className={classNames(`${S.plusSymbol} ${hangLengthClass}`)}>+</span>}

      {IS_CANCELLED && <CancelledBox className={S.cancelBox}/>}

      <ProductName name={tierName} className={classNames(S.qknName, {[S.isCancelled]: IS_CANCELLED})}/>
      <TagLine uriName={SUB.tierUriName} className={S.qknTagLine}/>

      {!isModal && MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>}
      
      <Price price={PRICE} renewalFrequency={renewalFrequency} className={S.price}/>

      <Expiry product={PRODUCT} className={S.qobExpiry}/>
      
      {showDash &&
        <span className={S.dash}/>
      }

      {!isModal && active && autoRenew && HAS_PAYMENT && (
        <button
          type="button"
          onClick={manageSubscription}
          className={S.qobCTA}
          data-testid="BTN_QOB_MANAGE_SUB"
        >Manage Subscription</button>
      )}

      {active && autoRenew && !HAS_PAYMENT && (
        <div className={S.TagLine}>Handle billing through your Quicken Classic payment.</div>
      )}

      {qknIsActive && qknHasArOn && active && !autoRenew && !isInGracePeriod && (
        <>
          <button
            type="button"
            onClick={handleAROn}
            className={S.qobCTA}
            data-testid="BTN_QOB_RESTART_SUB"
          >Resume Subscription</button>
          <Legal className={S.legal}/>
        </>
      )}

      {(qknIsActive && !active) || isInGracePeriod && (
        <button
          type="button"
          onClick={handleBuyNow}
          className={S.qobCTA}
          data-testid="BTN_BM_RENEW_NOW"
        >Buy Now</button>
      )}
    </div>
  );
};

QobPaid.displayName = 'QobTile';
QobPaid.propTypes   = {
  isModal:     PropTypes.bool,
  hideCircle:  PropTypes.bool,
  qknIsActive: PropTypes.bool,
  qknHasArOn: PropTypes.bool,
  listPosition: PropTypes.number
};

QobPaid.defaultProps = {
  isModal:    false,
  hideCircle: false,
  listPosition: 0
};

export { QobPaid };
