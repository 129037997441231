import Moment from 'moment';

//Simplifi Price Warnings
const expiredDatePast = expireOn => {
  const diff = Moment('May 31 2022').diff(Moment(expireOn), 'days');
  return diff;
};

export const getPriceWarning = (sku, expireOn) => {
  // Temporary price increase warnings
  const CUR_DATE = Moment();
  const WARNING_SKUS = ['170299', '170297'];
  const IS_WARNING_SKU = WARNING_SKUS.includes(sku);
  const WARNING_START = Moment('January 1, 2022 00:00:01');
  const WARNING_END = Moment('January 31, 2022 23:59:59');
  const WARNING_ACTIVE = (CUR_DATE >= WARNING_START && CUR_DATE <= WARNING_END);
  const PRICE_WARNING = (IS_WARNING_SKU && WARNING_ACTIVE);
  const MESSAGE = '*The monthly price for Simplifi will increase to $5.99 on February 1. You will be charged the new price when you renew in February.';

  const WARNING_SKUS_ANNUAL = ['170298', '170333', '170296', '170325', '170368', '170382', '170386', '170390', '170403'];
  const IS_ANN_WARNING_SKU = WARNING_SKUS_ANNUAL.includes(sku);
  const ANN_WARNING_START = Moment('March 29, 2022 00:00:01');
  const ANN_WARNING_END = Moment('May 31, 2022 23:59:59');
  const ANN_WARNING_ACTIVE = (CUR_DATE >= ANN_WARNING_START && CUR_DATE <= ANN_WARNING_END);
  const EXPIRED_CHECK = expiredDatePast(expireOn);
  const ANN_PRICE_WARNING = (IS_ANN_WARNING_SKU && ANN_WARNING_ACTIVE && (EXPIRED_CHECK <= 0));
  const ANN_MESSAGE = '*The annual price for Simplifi will increase to $47.99 on June 1 2022. You will be charged the new price at your next renewal.';

  return {
    PRICE_WARNING: PRICE_WARNING,
    PRICE_WARNING_MSG: MESSAGE,
    ANN_PRICE_WARNING: ANN_PRICE_WARNING,
    ANN_PRICE_WARNING_MSG: ANN_MESSAGE
  };
};
//End Simplifi Price Warnings




//Quicken Price Warnings
export const usPriceWarnings = (tierUriName, expireOn) => {
  const expiryDate = Moment(expireOn);
  const oldPrices = {
    'starter': '$41.88',
    'deluxe': '$59.88',
    'premier': '$83.88',
    'deluxe-standard-fp': '$84.99',
    'deluxe-flex-fp': '$94.99',
    'premier-standard-fp': '$109.99',
    'premier-flex-fp': '$119.99',
    'hab-rpm': '$119.88',
    'hab-standard-fp': '$134.99',
    'hab-flex-fp': '$144.99'
  };

  const newPrices = {
    'starter': '$47.88',
    'deluxe': '$71.88',
    'premier': '$95.88',
    'deluxe-standard-fp': '$71.88',
    'deluxe-flex-fp': '$71.88',
    'premier-standard-fp': '$95.88',
    'premier-flex-fp': '$95.88',
    'hab-rpm': '$131.88',
    'hab-standard-fp': '$131.88',
    'hab-flex-fp': '$131.88'
  };

  const dateConstants = {
    WAVE1_START: Moment('2024-01-26', 'YYYY-MM-DD'),
    WAVE1_END: Moment('2024-10-24', 'YYYY-MM-DD'),
    WAVE1_WARN_START: Moment('2023-12-27', 'YYYY-MM-DD'),
    WAVE1_WARN_END: Moment('2024-1-25', 'YYYY-MM-DD'),

    WAVE2_START: Moment('2024-10-25', 'YYYY-MM-DD'),
    WAVE2_END: Moment('2025-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_START: Moment('2024-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_END: Moment('2024-04-24', 'YYYY-MM-DD'),
  };

  let WARNING_START = false;
  let WARNING_END = false;
  let WARN_WAVE = '';

  if (expiryDate.isBetween(dateConstants.WAVE1_START, dateConstants.WAVE1_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE1_WARN_START;
    WARNING_END = dateConstants.WAVE1_WARN_START;
    WARN_WAVE = 'WAVE1';
  } else if (expiryDate.isBetween(dateConstants.WAVE2_START, dateConstants.WAVE2_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE2_WARN_START;
    WARNING_END = dateConstants.WAVE2_WARN_END;
    WARN_WAVE = 'WAVE2';
  } 

  const CUR_DATE = Moment().format('YYYY-MM-DD');
  const WARNING_TIERS = Object.keys(newPrices);
  const IS_WARNING_TIER = WARNING_TIERS.includes(tierUriName);
  const WARNING_ACTIVE = (
    (WARNING_START && WARNING_END) &&
    Moment(CUR_DATE).isBetween(WARNING_START, WARNING_END, undefined, '[]') // date inclusive
  );
  const PRICE_WARNING = (IS_WARNING_TIER && WARNING_ACTIVE);

  return {
    HAS_PRICE_UPDATE: PRICE_WARNING,
    WARN_WAVE: WARN_WAVE,
    WARNING_START: WARNING_START,
    PRICE: (WARN_WAVE == 'WAVE1') ? newPrices[tierUriName] : oldPrices[tierUriName],
    expireOn: expireOn
  };
};

export const caPriceWarnings = (tierUriName, expireOn) => {
  const expiryDate = Moment(expireOn);

  const oldPricesCa = {
    'cash-manager': '$47.88',
    'starter': '$47.88',
    'deluxe': '$71.88',
    'hab': '$107.88',
  };
  
  const newPricesCa = {
    'cash-manager': '$53.88',
    'starter': '$53.88',
    'deluxe': '$83.88',
    'hab': '$119.88',
  };

  const dateConstants = {
    WAVE1_START: Moment('2024-01-26', 'YYYY-MM-DD'),
    WAVE1_END: Moment('2024-10-24'),
    WAVE1_WARN_START: Moment('2023-12-27', 'YYYY-MM-DD'),
    WAVE1_WARN_END: Moment('2024-1-25', 'YYYY-MM-DD'),

    WAVE2_START: Moment('2024-10-25', 'YYYY-MM-DD'),
    WAVE2_END: Moment('2025-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_START: Moment('2024-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_END: Moment('2024-04-24', 'YYYY-MM-DD')
  };

  let WARNING_START = false;
  let WARNING_END = false;
  let WARN_WAVE = '';
  if (expiryDate.isBetween(dateConstants.WAVE1_START, dateConstants.WAVE1_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE1_WARN_START;
    WARNING_END = dateConstants.WAVE1_WARN_START;
    WARN_WAVE = 'WAVE1';
  } else if (expiryDate.isBetween(dateConstants.WAVE2_START, dateConstants.WAVE2_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE2_WARN_START;
    WARNING_END = dateConstants.WAVE2_WARN_END;
    WARN_WAVE = 'WAVE2';
  } 

  const CUR_DATE = Moment().format('YYYY-MM-DD');
  const WARNING_TIERS = Object.keys(newPricesCa);
  const IS_WARNING_TIER = WARNING_TIERS.includes(tierUriName);
  const WARNING_ACTIVE = (
    (WARNING_START && WARNING_END) &&
    Moment(CUR_DATE).isBetween(WARNING_START, WARNING_END, undefined, '[]') // date inclusive
  );
  const PRICE_WARNING = (IS_WARNING_TIER && WARNING_ACTIVE);

  return {
    HAS_PRICE_UPDATE: PRICE_WARNING,
    WARN_WAVE: WARN_WAVE,
    WARNING_START: WARNING_START,
    WARNING_END: WARNING_END,
    PRICE: (WARN_WAVE == 'WAVE1') ? newPricesCa[tierUriName] : oldPricesCa[tierUriName],
    OLD_PRICE: oldPricesCa[tierUriName],
    NEW_PRICE: newPricesCa[tierUriName],
    expireOn: expireOn
  };
};

export const getQknPriceWarn = (tierUriName, expireOn, country) => {
  if(country === 'ca') {
    return caPriceWarnings(tierUriName, expireOn);
  } else {
    return usPriceWarnings(tierUriName, expireOn);
  }
};
//End Quicken Price Warnings

