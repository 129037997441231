import PropTypes from 'prop-types';
import React     from 'react';

import * as S                from './cancel-ar-presenter.module.scss';
import { ModalWrapper }      from '../modal-wrapper';
import { ProductName }       from '../../common/product/prod-name';
import { XIcon }             from '../../common/assets/x-icon';
import { Message }           from '../../common/message';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import { getFullDate,
  isWithin42Days,
  isWithin3Days }               from '../../../helpers/time-helpers';
import CHAT_ICON                from '../../../assets/imgs/icons/icon-chat.svg';
import PHONE_ICON               from '../../../assets/imgs/icons/icon-phoneSupport.svg';
import { gtmLink, careTestObj } from '../../../helpers/tealium';

const timezoneStr = 'America/Los_Angeles';

let weekdayOptions = {
  weekday: 'long',
};

let dateOptions = {
  timeZone: timezoneStr,
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const getDateFormat = (options) => {
  const dateObj = new Intl.DateTimeFormat([], options);
  return dateObj.format();
};

const chatOpenHours = (chatHours) => {
  const curLocalTime = new Date();
  const weekDay = getDateFormat(weekdayOptions);
  const hoursArr = chatHours[weekDay].split(' - ');
  const hoursStart = new Date(getDateFormat(dateOptions) + ' ' + hoursArr[0] + ' GMT-8:00');
  const hoursEnd = new Date(getDateFormat(dateOptions) + ' ' + hoursArr[1] + ' GMT-8:00');

  return (hoursStart <= curLocalTime && hoursEnd >= curLocalTime) ? true : false;
};

const getPhoneTestValue = (emailId) => {
  const validEmailId = (typeof emailId === 'string' || emailId instanceof String);
  if(validEmailId) {
    const phoneTestSplit = ((parseInt(emailId.slice(-1)) % 2) == 0) ? true : false;
    return phoneTestSplit;
  } else {
    return false;
  }
};

const CancelARPresenter = ({
  product,
  message,
  points,
  onClose,
  onSubmit,
  remindLaterHandler
}) => {
  const { state }       = useGlobalCtx();
  const GENESYS_EXISTS  = (typeof Genesys !== 'undefined');
  const GENESYS_ENABLED = state.ui.chatHours.chatEnabled;
  const HOURS_OPEN = chatOpenHours(state.ui.chatHours.chatHours);
  // const CAN_PHONE = (GENESYS_ENABLED && HOURS_OPEN);
  const CAN_GENESYS = (GENESYS_EXISTS && GENESYS_ENABLED && HOURS_OPEN);
  const phoneTestSplit = getPhoneTestValue(state.emailId);
  const { expireOn, productName, tierUriName, remindLater } = ProductSelectors.getSubscription(state, product);

  const {isDisabled, hasError} = state.modal;
  const IS_BM_PRODUCT  = (product === 'billmanager' || product === 'billmanager-promotion');
  const IS_QKN_PRODUCT = (product === 'quicken-us' || product === 'quicken-ca');
  const IS_QEM         = (product === 'quicken-estate-manager');
  const IS_QOB         = (product === 'quicken-online-backup');
  const HAS_QOB        = ProductSelectors.getSubscription(state, 'quicken-online-backup');
  const QOB_ACTIVE     = HAS_QOB ? HAS_QOB.active : false ;
  const NAME           = ProductSelectors.getProductName(state, product);
  const DATE           = getFullDate(expireOn);
  const ABILITY_TO     = IS_BM_PRODUCT ? 'schedule payments' : `use your ${productName}`;
  const REMIND_ME_LATER_ENABLED = remindLater;
  const REMIND_QKN_VALID = !isWithin42Days(expireOn);
  const REMIND_SIMP_VALID = !isWithin3Days(expireOn);
  const cancelPhoneTest = phoneTestSplit && IS_QKN_PRODUCT;
  const modalTitle = IS_QOB ? 'Do you really want to cancel Quicken Online Backup renewal?' : 'Did you know that...';

  let REMIND_DATE_VALID = false;
  
  if (IS_QKN_PRODUCT) {
    REMIND_DATE_VALID = REMIND_QKN_VALID;
  } else if (!IS_BM_PRODUCT && !IS_QKN_PRODUCT) {
    REMIND_DATE_VALID = REMIND_SIMP_VALID;
  }

  const gtmAction = (action) => {
    let data = {
      userId:    state.userId,
      category:  null,
      label:     null,
    };

    switch (action) {
      case 'Control-View':
        data.category = 'MyAcct-CareTestV4-Control';
        data.label = 'CareTest - Control Viewed';
        break;
      case 'Control-Cancel':
        data.category = 'MyAcct-CareTestV4-Control';
        data.label = 'CareTest - Control Cancelled Sub';
        break;
      case 'Control-Closed':
        data.category = 'MyAcct-CareTestV4-Control';
        data.label = 'CareTest - Control Closed Modal';
        break;
      case 'Control-Chatted':
        data.category = 'MyAcct-CareTestV4-Control';
        data.label = 'CareTest - Control Clicked Chat';
        break;
      case 'Control-Called':
        data.category = 'MyAcct-CareTestV4-Control';
        data.label = 'CareTest - Control Clicked Phone';
        break;


      case 'Test-View':
        data.category = 'MyAcct-CareTestV4-Variant';
        data.label = 'CareTest - Variant Viewed';
        break;
      case 'Test-Cancel':
        data.category = 'MyAcct-CareTestV4-Variant';
        data.label = 'CareTest - Variant Cancelled Sub';
        break;
      case 'Test-Closed':
        data.category = 'MyAcct-CareTestV4-Variant';
        data.label = 'CareTest - Variant Closed Modal';
        break;
      case 'Test-Called':
        data.category = 'MyAcct-CareTestV4-Variant';
        data.label = 'CareTest - Variant Clicked Phone';
        break;
      default:
        data.category = 'MyAcct-CareTest';
        data.label = 'CareTest - Unhandled Action';
        break;
    }

    if (IS_QKN_PRODUCT && GENESYS_EXISTS) {
      gtmLink(careTestObj(data));
    }
  };

  const handleChatClick = React.useCallback(() => {
    if(!cancelPhoneTest) {
      const action = 'Control-Chatted';
      gtmAction(action);
    }

    // eslint-disable-next-line
    Genesys('command', 'Messenger.open', {});
  }, []);

  const handlePhoneClick = () => {
    const action = cancelPhoneTest ? 'Test-Called' : 'Control-Called';
    gtmAction(action);
  };

  const trackAndClose = () => {
    const action = cancelPhoneTest ? 'Test-Closed' : 'Control-Closed';
    gtmAction(action);
    onClose();
  };

  const trackAndCancel = () => {
    const action = cancelPhoneTest ? 'Test-Cancel' : 'Control-Cancel';
    gtmAction(action);
    onSubmit();
  };
  
  cancelPhoneTest ? gtmAction('Test-View') : gtmAction('Control-View');
  
  return (
    <ModalWrapper heading="Cancel Renewal" onClose={trackAndClose} className={S.cancelModal}>
      <div className={S.modalBody}>
        {hasError && <Message type="GENERIC_ERROR"/>}

        <Message
          className={S.label}
          type="ERROR"
          title={modalTitle}
          messages={[message]}
        />

        <div className={S.midRow}>
          <div className={`${S.productCard} ${tierUriName}`}>
            <ProductName name={NAME}/>

            {product === 'billmanager' &&
              <p className={S.renewalMsg}>Your subscription will expire on {DATE}</p>
            }

            {product != 'billmanager' &&
              <p className={S.renewalMsg}>You will be able to {ABILITY_TO} until {DATE}</p>
            }
            
            {IS_QKN_PRODUCT && QOB_ACTIVE &&
              <p className={S.renewalMsg}>Your Online Backup add-on subscription will also be cancelled.</p>
            }

          </div>

          {points.length > 0 && (
            <ul>
              {points.map((str, index) => (
                <li key={index}>
                  <XIcon color="#F2866D"/>
                  <span>{str}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* adblock scenario, hours our open but adblock is stopping Genesys object from loading */}
        { HOURS_OPEN && !GENESYS_EXISTS && (
          <div className={S.genesys}>
            <img src={PHONE_ICON} alt="Message Icon" />
            <p>
              We&apos;re here to help! You can talk to one of our expert agents for free at 
              <a
                onClick={handlePhoneClick}
                className={S.chatBtn} 
                href="tel:650-215-9008"> (650) 215-9008
              </a>
            </p>
          </div>
        )}

        {/* simplifi use case, show them chat */}
        {HOURS_OPEN && !IS_BM_PRODUCT && !IS_QKN_PRODUCT && GENESYS_EXISTS && (
          <div className={S.genesys}>
            <img src={CHAT_ICON} alt="Message Icon" />
            <p>
              Before you go, a live support agent is available just one click away,
              <button
                type="button"
                className={S.chatBtn}
                onClick={handleChatClick}
              >chat now</button>
            </p>
          </div>
        )}

        {/* quicken only, phone test control */}
        {(CAN_GENESYS && !cancelPhoneTest && IS_QKN_PRODUCT) && (
          <div className={S.genesys}>
            <img src={CHAT_ICON} alt="Message Icon"/>
            <p>
              Before you go, a live support agent is available,
              <button
                type="button"
                className={S.chatBtn}
                onClick={handleChatClick}
              >chat now</button>
              or call us at 
              <a
                onClick={handlePhoneClick}
                className={S.chatBtn}
                href="tel:650-215-9008"> (650) 215-9008
              </a>
            </p>
          </div>
        )}

        {/* quicken only, phone test variant */}
        {(CAN_GENESYS && cancelPhoneTest && IS_QKN_PRODUCT) && (
          <div className={S.genesys}>
            <img src={PHONE_ICON} alt="Message Icon" />
            <p>
              We&apos;re here to help! You can talk to one of our expert agents for free at 
              <a
                onClick={handlePhoneClick}
                className={S.chatBtn} 
                href="tel:650-215-9008"> (650) 215-9008
              </a>
            </p>
          </div>
        )}

        <div className={S.btnCols}>
          <div className={[`${S.btnRow} ${S.cancelRow}`]}>
            <button
              type="button"
              onClick={trackAndCancel}
              className={[`${S.outlineCTABtn} ${S.cancelBtn}`]}
              disabled={isDisabled}
            >Cancel renewal</button>
          </div>

          {!REMIND_ME_LATER_ENABLED && !IS_BM_PRODUCT && REMIND_DATE_VALID && !IS_QEM && !IS_QOB &&
            <div className={[`${S.btnRow} ${S.remindRow}`]}>
              <button
                type="button"
                onClick={remindLaterHandler}
                className={[`${S.outlineCTABtn} ${S.remindBtn}`]}
                disabled={isDisabled}
              >Remind me later</button>

              {IS_QKN_PRODUCT &&
                <span className={S.remindTxt}>
                  Email me 6 weeks before my subscription renews
                </span>
              }

              {!IS_QKN_PRODUCT &&
                <span className={S.remindTxt}>
                  Email me 3 days before my subscription renews
                </span>
              }

            </div>
          }

          <div className={[`${S.btnRow} ${S.keepRenewRow}`]}>
            <button
              type="button"
              // onClick={onClose}
              onClick={trackAndClose}
              className={S.contentCTABtn}
              disabled={isDisabled}
            >Keep renewal on</button>
          </div>

        </div>
      </div>
    </ModalWrapper>
  );
};

CancelARPresenter.displayName = 'CancelARPresenter';
CancelARPresenter.propTypes   = {
  product: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  points:  PropTypes.arrayOf(PropTypes.string).isRequired,

  onClose:  PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  remindLaterHandler: PropTypes.func.isRequired
};

export { CancelARPresenter };
